/* LandingPage.css */
.landing-page {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.header {
  position: fixed;
  top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.text-button {
  color: white;
  font-size: 1.5rem;
  margin: 0 20px;
  cursor: pointer;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
}

h1 {
  font-size: 3rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.signup-button {
  background-color: lightblue;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
}

/* Add more global styles as needed */
