/* SignUpPage.css */
.sign-up-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  padding: 20px;
  max-width: 600px; /* Set a maximum width */
  margin: 0 auto; /* Center horizontally */
}

.text-button {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 10px 20px;
  background-color: lightblue;
  color: white;
  cursor: pointer;
  text-decoration: none; /* Remove default link underline */
  display: inline-block;
}

.text-button:hover {
  background-color: #007acc; /* Change color on hover */
}

.content {
  text-align: center;
  width: 100%;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: black;
}

/* Update the color of subtext */
.subtext {
  font-size: 1.2rem;
  color: black; /* Change text color to black */
  margin-bottom: 20px; /* Add margin to separate from the input */
}

input {
  width: 100%;
  max-width: 400px; /* Set a maximum width for the input */
  padding: 10px;
  margin: 10px 0;
  font-size: 1.2rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.continue-button {
  background-color: lightblue;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px; /* Move the button below the input */
}

.continue-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.small-text {
  font-size: 0.8rem;
  color: #888;
  line-height: 1.5;
  max-width: 400px; /* Set a maximum width for the small text */
  margin: 0 auto; /* Center the small text horizontally */
  margin-top: 20px;
}
